<template>
  <nuxt-page />
</template>
<script setup lang="ts">
import { definePageMeta } from '@/.nuxt/imports';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useNoticeStore } from '@/stores/notice.store';

definePageMeta({
  menuAuthId: MenuAuthorityIds.PROJECT_PRODUCT
});

const appStore = useAppStore();

const noticeStore = useNoticeStore();

const { fetchLatestNotice } = noticeStore;

const init = async () => {
  fetchLatestNotice();
  appStore.fetchProjectProductItems();
};

init();

</script>
